import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/layouts/HomeLayout.jsx";
import Spacer from '@components/Spacer/Spacer';
import * as styles from '@layouts/HomeLayout.module.scss';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1>{`Cadre`}</h1>
    <div className={styles.workDetails}>
  Engineering team <br /> New York, NY &mdash; 2016&#8202;&ndash;&#8202;2018
    </div>
    <p>{`Joined a small team to help provide better access to real estate investments for retail investors, working across the web platform, internal tooling, growth/SEO, and community outreach.`}</p>
    <aside>
  <hr />
    </aside>
    <h2>{`Technology`}</h2>
    <ul>
      <li parentName="ul">{`Backend built with Python (Django framework)`}</li>
      <li parentName="ul">{`Front-end build with JavaScript (React)`}<ul parentName="li">
          <li parentName="ul">{`Visualizations for internal tools were created using D3.js`}</li>
          <li parentName="ul">{`Mapbox was used for interactive mapping and polygon layers`}</li>
        </ul></li>
    </ul>
    <h2>{`Culture`}</h2>
    <ul>
      <li parentName="ul">{`Founded Cadre Book Club`}</li>
      <li parentName="ul">{`Hosted CoderDojo events`}</li>
      <li parentName="ul">{`Created `}<a parentName="li" {...{
          "href": "/project/build-ball"
        }}>{`Build Ball`}</a></li>
    </ul>
    <Spacer height={24} mdxType="Spacer" />
    <aside>
      <hr></hr>
      <p>{`Prior to Cadre, Adam co-founded and lead Engineering at two NYC-based startups, `}<a parentName="p" {...{
          "href": "/startup/gigwax"
        }}>{`Gigwax`}</a>{` and `}<a parentName="p" {...{
          "href": "/startup/pradux"
        }}>{`Pradux`}</a>{`.`}</p>
    </aside>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      